import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default function MainSlider() {

  const [animating, setAnimating] = useState(false);
  const settings = {
    dots: false,
    arrows: false,
    autoplay: true,
    beforeChange: (current, next) => {
      setAnimating(true);
    },
    afterChange: () => {
      setAnimating(false);
    }
  };

  return (
    <div>
      <div className='m-0'>
        <Slider {...settings}>
          <div className='h-full w-full'>
            <div className='lg:h-[750px] md:p-0 md:h-[550px] py-12 w-full bg-no-repeat bg-cover flex items-center justify-center gap-5' style={{ backgroundImage: 'url(http://kodeforest.net/wp-demo/sportspro/wp-content/uploads/revslider/gameplay_soccer_main/bg-2.jpg)' }}>
              <div className={`relative lg:w-[500px] md:w-[400px] hidden md:block lg:mr-12 md:mr-2 ${animating ? 'slide-in-from-left' : null}`}>
                <img className='w-[300px] lg:w-auto' src='http://kodeforest.net/wp-demo/sportspro/wp-content/uploads/revslider/gameplay_soccer_main/hero.png' />
                <img className='absolute lg:top-16 lg:-right-10 md:top-10 md:right-[50px] lg:h-[100px] md:h-[60px]' src='http://kodeforest.net/wp-demo/sportspro/wp-content/uploads/revslider/gameplay_soccer_main/ball.png' />
              </div>
              <div className={`text-white ${animating ? 'slide-in-from-right' : null}`}>
                <p className='uppercase font-semibold md:text-2xl lg:text-3xl'>Complete</p>
                <p className='uppercase font-semibold md:text-4xl lg:text-5xl'>Fixture & result</p>
                <p className='uppercase font-medium md:text-xl lg:text-2xl'>of football club</p>
                <div className='flex items-center gap-5 mt-5'>
                  <button className='bg-[#ffbe00] font-semibold uppercase md:px-9 md:py-3 px-4 py-2 hover:bg-[#ffd65c] duration-500'>See more</button>
                  <button className='bg-transparent font-semibold uppercase border md:px-9 md:py-3 px-4 py-2'>Purchase now</button>
                </div>
              </div>
            </div>
          </div>
          <div className='h-full w-full'>
            <div className='lg:h-[750px] md:p-0 md:h-[550px] py-12 w-full bg-no-repeat bg-cover flex items-center justify-center gap-5' style={{ backgroundImage: 'url(	http://kodeforest.net/wp-demo/sportspro/wp-content/uploads/revslider/gameplay_soccer_main/bg-3.jpg)' }}>
              <div className={`text-white text-center ${animating ? 'slide-in-from-left' : null}`}>
                <p className='uppercase font-medium md:text-2xl lg:text-3xl'>We are</p>
                <p className='uppercase font-semibold md:text-4xl lg:text-5xl md:my-3 my-1'>Developing the game</p>
                <p className='font-medium md:text-xl lg:text-2xl'>Be Our Partner</p>
                <div className='flex items-center justify-center gap-5 mt-5'>
                  <button className='bg-transparent font-semibold border md:px-9 md:py-3 px-4 py-2'>Purchase now</button>
                  <button className='bg-transparent font-semibold border md:px-9 md:py-3 px-4 py-2'>See More</button>
                </div>
              </div>
              <div className={`relative lg:w-[500px] hidden md:block lg:ml-12 ml:mr-2 ${animating ? 'slide-in-from-right' : null}`}>
                <img className='w-[300px] lg:w-auto' src='http://kodeforest.net/wp-demo/sportspro/wp-content/uploads/revslider/gameplay_soccer_main/man-single.png' />
                <img className='absolute md:-top-8 md:-left-14 lg:h-[80px] md:h-[60px]' src='http://kodeforest.net/wp-demo/sportspro/wp-content/uploads/revslider/gameplay_soccer_main/ball.png' />
              </div>
            </div>
          </div>
        </Slider>
      </div>
      {/* <div>
        <div className='grid md:grid-cols-5 xl:grid-cols-4'>
          <div className='bg-[#1b212b] p-2 flex items-center md:col-span-2 xl:col-span-1 xl:pl-[160px]'>
            <p className='text-[#ffdc11] uppercase font-semibold text-lg font-opensans'>Breaking News</p>
          </div>
          <div className='md:col-span-3 xl:col-span-3 p-2 bg-black flex justify-between items-center gap-4 xl:pr-[160px]'>
            <p className='text-[#fff] line-clamp-1'>Bresaola alcatra boudin andouille, ball tip rump pancetta shoulder. Beef ribs turducken tail flank. Leberkas pancetta tri-tip biltong spare ribs, pork loin bresaola porchetta ribeye shankle chuck tail hamburger pork bacon. Picanha tail pastrami venison kevin. Ham leberkas meatball, kielbasa shoulder pancetta beef shank meatloaf drumstick salami shankle. Frankfurter pork belly shoulder pig, alcatra doner ball tip chicken prosciutto swine salami. Brisket meatball frankfurter turkey leberkas ball tip jowl tongue t-bone.</p>
            <div className='flex gap-2'>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="white" className="w-5 h-5 cursor-pointer p-[1px] bg-[#ffdc11] rounded">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="white" class="w-5 h-5 cursor-pointer p-[1px] bg-[#ffdc11] rounded">
                <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
              </svg>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  )
}