import React, { useEffect, useState } from 'react';
import { Button, Dropdown } from 'antd';
import { LatestNewsList } from '../../Api/Requsts/Main';

export default function Header() {

  const [news, setNews] = useState([]);
  const [newsCount, setNewsCount] = useState(0);
  const items = [
    { key: '1', label: (<a className='hover:text-[#878787] duration-300'>Home</a>) },
    { key: '1', label: (<a className='hover:text-[#878787] duration-300'>Matches</a>) },
    { key: '1', label: (<a className='hover:text-[#878787] duration-300'>Blog</a>) },
    { key: '1', label: (<a className='hover:text-[#878787] duration-300'>Team</a>) },
    { key: '1', label: (<a className='hover:text-[#878787] duration-300'>Player</a>) },
    { key: '1', label: (<a className='hover:text-[#878787] duration-300'>Pages</a>) },
    { key: '1', label: (<a className='hover:text-[#878787] duration-300'>Contact Us</a>) },
  ];

  const getLatestNewsList = async () => {
    try {
      const { data } = await LatestNewsList();
      setNews(data?.data);
    } catch (error) { }
  }

  useEffect(() => {
    getLatestNewsList();
  }, []);

  return (
    <div className='bg-[#ffdc11]' id='home'>
      <div className='xl:pl-[160px] xl:mr-[160px] bg-[#1b212b] main-header'>
        <div className='flex gap-6 text-white py-4 px-2'>
          <i className="fa-brands fa-facebook-f cursor-pointer"></i>
          <i className="fa-brands fa-google-plus-g cursor-pointer"></i>
          <i className="fa-brands fa-linkedin-in cursor-pointer"></i>
          <i className="fa-brands fa-twitter cursor-pointer"></i>
        </div>

        <div className='bg-white flex justify-between items-center px-4 py-6'>
          <img className='cursor-pointer' src='http://kodeforest.net/wp-demo/sportspro/wp-content/uploads/2017/03/ultimate-Sports-pro.png' alt='logo' />

          <div className='hidden lg:block'>
            <div className='flex items-center gap-3 font-montserrat'>
              <a className='uppercase font-semibold cursor-pointer px-4 py-2 hover:bg-[#ffdc11] hover:text-white duration-500'>Home</a>
              <a className='uppercase font-semibold cursor-pointer px-4 py-2 hover:bg-[#ffdc11] hover:text-white duration-500'>Matches</a>
              <a className='uppercase font-semibold cursor-pointer px-4 py-2 hover:bg-[#ffdc11] hover:text-white duration-500'>Blog</a>
              <a className='uppercase font-semibold cursor-pointer px-4 py-2 hover:bg-[#ffdc11] hover:text-white duration-500'>Team</a>
              <a className='uppercase font-semibold cursor-pointer px-4 py-2 hover:bg-[#ffdc11] hover:text-white duration-500'>Player</a>
              <a className='uppercase font-semibold cursor-pointer px-4 py-2 hover:bg-[#ffdc11] hover:text-white duration-500'>Pages</a>
              <a className='uppercase font-semibold cursor-pointer px-4 py-2 hover:bg-[#ffdc11] hover:text-white duration-500'>Contact us</a>
            </div>
          </div>

          <div className='lg:hidden block'>
            <Dropdown
              menu={{ items }}
              trigger={['click']}
              placement="bottomRight"
            >
              <Button className='p-0 border-none outline-none'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" class="w-7 h-7">
                  <path fill-rule="evenodd" d="M3 6.75A.75.75 0 0 1 3.75 6h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 6.75ZM3 12a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 12Zm0 5.25a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75Z" clip-rule="evenodd" />
                </svg>
              </Button>
            </Dropdown>
          </div>
        </div>

        <div className='grid md:grid-cols-5 xl:grid-cols-4'>
          <div className='bg-[#1b212b] p-2 flex items-center md:col-span-2 xl:col-span-1'>
            <div className='flex justify-between items-center w-full'>
              <p className='text-[#ffdc11] uppercase font-semibold text-lg font-opensans'>Latest News :</p>
              <div className='flex gap-2'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="white" className="w-5 h-5 cursor-pointer" onClick={() => { setNewsCount(newsCount != 0 ? newsCount - 1 : newsCount) }}>
                  <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="white" class="w-5 h-5 cursor-pointer" onClick={() => { setNewsCount(newsCount < news?.length - 1 ? newsCount + 1 : newsCount) }}>
                  <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                </svg>
              </div>
            </div>
          </div>
          <div className='md:col-span-3 xl:col-span-3 p-2 flex items-center bg-[#ffdc11]'>
            <p className='text-black font-semibold line-clamp-1'>{news[newsCount]?.title}</p>
          </div>
        </div>
      </div>
    </div>
  )
}