import React, { useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import { BDayPlayers, LatestBlogList, LatestNewsList, PlayerRanking } from '../Api/Requsts/Main';
import moment from 'moment';

export default function Home() {

    const [playerRanking, setPlayerRanking] = useState([]);
    const [bDays, setBDays] = useState([]);
    const [news, setNews] = useState([]);
    const [blogs, setBlogs] = useState([]);

    const getPlayerRanking = async () => {
        try {
            const { data } = await PlayerRanking();
            setPlayerRanking(data?.data);
        } catch (error) { }
    }
    const getBDayPlayes = async () => {
        try {
            const { data } = await BDayPlayers();
            setBDays(data?.data);
        } catch (error) { }
    }

    const getLatestNewsList = async () => {
        try {
            const { data } = await LatestNewsList();
            setNews(data?.data);
        } catch (error) { }
    }

    const getLatestBlogList = async () => {
        try {
            const { data } = await LatestBlogList();
            setBlogs(data?.data);
        } catch (error) { }
    }

    useEffect(() => {
        getPlayerRanking();
        getBDayPlayes();
        getLatestNewsList();
        getLatestBlogList();
    }, []);

    return (
        <div className='bg-[#f1f1f1]'>
            <div className='px-2 xl:px-[160px] py-8'>

                <div className='grid lg:grid-cols-3 gap-5'>
                    {/* ------ Featured Videos ------ */}
                    <div className='lg:col-span-2 col-span-1'>
                        <div className='bg-white border-t-8 border-[#ffbe00] p-4 w-full'>
                            <p className='text-xl'>Featured Videos</p>
                        </div>
                        <div className='mx-0 mt-6'>
                            <div>
                                <iframe
                                    className='w-full h-[280px] sm:h-[380px] md:h-[480px]'
                                    src='https://www.youtube.com/embed/mBgDXoB2cms'
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                />
                            </div>
                        </div>
                    </div>
                    {/* ------ Player ranking ------ */}
                    <div className='bg-white'>
                        <div className='bg-white border-t-8 border-[#ffbe00] p-4 w-full'>
                            <p className='text-xl'>Player Ranking</p>
                        </div>
                        <div className='h-[530px] overflow-y-auto overflow-x-auto'>
                            {
                                playerRanking?.length > 0 ? (
                                    <div className='w-full'>
                                        <table className='w-full border-collapse border'>
                                            <thead>
                                                <tr className='text-left uppercase bg-[#f9f9f9]'>
                                                    <th className='p-4'>Player</th>
                                                    <th className='p-4'>Team</th>
                                                    <th className='p-4'>Likes</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    playerRanking?.map((team, index) => (
                                                        <tr key={index} className={`text-[#777] text-center border-t ${index % 2 == 0 ? 'bg-white' : 'bg-[#f9f9f9]'}`}>
                                                            <td className='p-2 flex items-center'>
                                                                <p className='mx-4'>{index + 1}</p>
                                                                <img src={team?.player_image} onError={(e) => { e.target.onError = null; e.target.src = '/assets/images/user.png' }} className='h-9 mr-4 rounded-full' alt='logo' />
                                                                <p className='whitespace-nowrap'>{team?.player_name}</p>
                                                            </td>
                                                            <td className='p-2 whitespace-nowrap'>{team?.team_name}</td>
                                                            <td className='p-2'>{team?.total_like}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                ) : (
                                    <div className='flex justify-center items-center h-full'>
                                        <div className='text-gray-400'>No Data</div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>

                <div className='grid lg:grid-cols-3 gap-5'>
                    <div className='lg:col-span-2 grid sm:grid-cols-2 gap-5 mt-6 lg:mt-0'>
                        {/* ------ News list ------ */}
                        {
                            news?.slice(0, 2)?.map((item, index) => (
                                <div className='bg-white border border-slate-300 cursor-pointer hover:shadow-2xl duration-500' key={index}>
                                    <img src={item?.news_image} className='w-full h-[230px]' alt='image' />

                                    <div className='p-4'>
                                        {/* <p className='text-[#ffbe00] uppercase font-semibold'>Players</p> */}
                                        <p className='font-semibold text-xl uppercase line-clamp-1'>{item?.title}</p>
                                        <div className='text-gray-400 flex items-center flex-wrap gap-4'>
                                            <div className='flex items-center gap-2'>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 25" stroke-width="1.5" stroke="currentColor" className="w-4 h-4 mt-[2px]">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                                </svg>
                                                <p className='text-sm'>{item?.time_format}</p>
                                            </div>
                                            <div className='flex items-center gap-2'>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 25" stroke-width="1.5" stroke="currentColor" className="w-4 h-4">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
                                                </svg>
                                                <p className='text-sm'>{moment(item?.created_at).format('LL')}</p>
                                            </div>
                                        </div>
                                        <p className='text-[#666] leading-7 my-4 line-clamp-3' dangerouslySetInnerHTML={{ __html: item?.description }}></p>

                                        <button className='uppercase text-sm font-semibold text-[#777] hover:text-[#ffbe00] duration-300'>Read More</button>
                                    </div>

                                    <div className='border-t border-slate-300 p-2 flex items-center flex-wrap gap-4'>
                                        <div className='flex items-center gap-2'>
                                            <Avatar size='35' round src='assets/images/user.png' name="Admin" />
                                            <p className='text-[#666] hover:text-[#ffbe00] duration-300'>Admin</p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }

                        {/* ------ Channel's videos ------ */}
                        <div className='sm:col-span-2'>
                            <div className='bg-white border-t-8 border-[#ffbe00] p-4 w-full'>
                                <p className='text-xl'>Fans Club</p>
                            </div>
                            <div className='mx-0'>
                                <div>
                                    <iframe
                                        className='w-full h-[280px] sm:h-[380px] md:h-[480px]'
                                        src='https://www.youtube.com/embed/RUAMuykk-NI'
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    />
                                </div>
                            </div>
                        </div>

                        {/* ------ Blog list ------ */}
                        {
                            blogs?.slice(0, 2)?.map((item, index) => (
                                <div className='bg-white border border-slate-300 cursor-pointer hover:shadow-2xl duration-500' key={index}>
                                    <img src={item?.blogs_image} className='w-full h-[230px]' alt='image' />

                                    <div className='p-4'>
                                        {/* <p className='text-[#ffbe00] uppercase font-semibold'>Players</p> */}
                                        <p className='font-semibold text-xl uppercase line-clamp-1'>{item?.title}</p>
                                        <div className='text-gray-400 flex items-center flex-wrap gap-4'>
                                            <div className='flex items-center gap-2'>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 25" stroke-width="1.5" stroke="currentColor" className="w-4 h-4 mt-[2px]">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                                </svg>
                                                <p className='text-sm'>{item?.time_format}</p>
                                            </div>
                                            <div className='flex items-center gap-2'>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 25" stroke-width="1.5" stroke="currentColor" className="w-4 h-4">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
                                                </svg>
                                                <p className='text-sm'>{moment(item?.created_at).format('LL')}</p>
                                            </div>
                                        </div>
                                        <p className='text-[#666] leading-7 my-4 line-clamp-3' dangerouslySetInnerHTML={{ __html: item?.description }}></p>

                                        <button className='uppercase text-sm font-semibold text-[#777] hover:text-[#ffbe00] duration-300'>Read More</button>
                                    </div>

                                    <div className='border-t border-slate-300 p-2 flex items-center flex-wrap gap-4'>
                                        <div className='flex items-center gap-2'>
                                            <Avatar size='35' round src='assets/images/user.png' name="Admin" />
                                            <p className='text-[#666] hover:text-[#ffbe00] duration-300'>Admin</p>
                                        </div>
                                        <div className='flex items-center gap-2'>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                            </svg>
                                            <p className='text-[#666] hover:text-[#ffbe00] duration-300'>{item?.views_count} Views</p>
                                        </div>
                                        <div className='flex items-center gap-2'>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 20.25c4.97 0 9-3.694 9-8.25s-4.03-8.25-9-8.25S3 7.444 3 12c0 2.104.859 4.023 2.273 5.48.432.447.74 1.04.586 1.641a4.483 4.483 0 0 1-.923 1.785A5.969 5.969 0 0 0 6 21c1.282 0 2.47-.402 3.445-1.087.81.22 1.668.337 2.555.337Z" />
                                            </svg>
                                            <p className='text-[#666] hover:text-[#ffbe00] duration-300'>{item?.comment_count} Comment</p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>

                    <div className='lg:mt-6'>
                        {/* ------ Birthday list ----- */}
                        <div>
                            <div className='bg-white border-t-8 border-[#ffbe00] p-4 w-full'>
                                <p className='text-xl'>Magic of May</p>
                            </div>
                            <div className='bg-white border-t border-slate-200 pb-6 overflow-y-auto h-[500px]'>
                                {bDays?.length > 0 ? (
                                    <div>
                                        {
                                            bDays?.map((item, index) => (
                                                <div key={index} className='flex gap-4 m-6 cursor-pointer text-[#777] hover:text-[#ffbe00] duration-300'>
                                                    <img src={item?.player_image} className='h-[70px] w-[70px]' alt='image' onError={(e) => { e.target.onError = null; e.target.src = '/assets/images/player.png' }} />
                                                    <div>
                                                        <p className='line-clamp-1 font-semibold'>{item?.player_name} ({item?.team_name})</p>
                                                        <p className='text-[#7d8386] line-clamp-1'>{item?.descriptions}</p>
                                                        <p className='line-clamp-1 font-semibold text-[#777]'>{moment(item?.dob).format('LL')}</p>
                                                        <div className='flex gap-2 items-center'>
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="red" class="w-6 h-6">
                                                                <path d="m11.645 20.91-.007-.003-.022-.012a15.247 15.247 0 0 1-.383-.218 25.18 25.18 0 0 1-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0 1 12 5.052 5.5 5.5 0 0 1 16.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 0 1-4.244 3.17 15.247 15.247 0 0 1-.383.219l-.022.012-.007.004-.003.001a.752.752 0 0 1-.704 0l-.003-.001Z" />
                                                            </svg>
                                                            <p className='text-black'>{item?.total_like}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                ) : (
                                    <div className='flex justify-center items-center h-full'>
                                        <div className='text-gray-400'>No Data</div>
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* ------ Download app ----- */}
                        <div className='grid sm:grid-cols-2 lg:grid-cols-1 gap-5'>
                            <div className='h-[370px] bg-white flex justify-center items-center p-6 mt-6 hover:text-[#ffbe00] duration-300'>
                                <div>
                                    <p className='text-center text-4xl font-semibold'>Download App</p>
                                    <div className='mt-8 mb-3 flex justify-center gap-5'>
                                        <img className='cursor-pointer' src='https://framerusercontent.com/images/daGlY7MhNnhjSLU3YjhdXlzBkOE.svg' alt='icon' />
                                        <img className='cursor-pointer' src='https://framerusercontent.com/images/HIwWMWocSGC4QVb8P4REjDpoq8.svg' alt='icon' />
                                    </div>
                                </div>
                            </div>
                            <div className='sm:mt-6 lg:mt-0'>
                                <div className='h-[370px] flex items-center justify-center bg-blend-overlay bg-[#0000009b] bg-no-repeat bg-cover' style={{ backgroundImage: 'url(https://images.unsplash.com/photo-1426604966848-d7adac402bff?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8bmF0dXJlfGVufDB8fDB8fHww)' }}>
                                    <p className='text-white uppercase font-semibold text-3xl sm:text-4xl text-center'>Advertisement Banner</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}