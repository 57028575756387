import React from 'react';

export default function Footer() {
  return (
    <div className='bg-black'>
      <div className='xl:px-[160px] px-4'>
        <div className='flex justify-center'>
          <a href='#home'>
            <div class="w-0 h-0 relative border-l-[40px] border-l-transparent border-t-[45px] border-t-[#ffbe00] border-r-[40px] border-r-transparent cursor-pointer">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3" stroke="white" className="w-6 h-6 absolute -top-10 -left-3">
                <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
              </svg>
            </div>
          </a>
        </div>

        <div className='py-6 mb-12'>
          <div className='grid sm:grid-cols-2 sm:px-0 px-4 md:grid-cols-3 gap-12'>
            <div>
              <img className='cursor-pointer' src='http://kodeforest.net/wp-demo/sportspro/wp-content/uploads/2017/03/ultimate-Sports-pro-1.png' alt='logo' />
              <div className='mt-6'>
                <div className='flex items-center gap-4'>
                  <div className='w-[35px] h-[35px] flex items-center justify-center text-white border rounded-full cursor-pointer hover:bg-[#ffbe00] hover:border-[#ffbe00] duration-300'>
                    <i class="fa-solid fa-house"></i>
                  </div>
                  <p className='text-white'>91 Avenue Street, New York City.</p>
                </div>
                <div className='flex items-center gap-4 mt-5'>
                  <div className='w-[35px] h-[35px] flex items-center justify-center text-white border rounded-full cursor-pointer hover:bg-[#ffbe00] hover:border-[#ffbe00] duration-300'>
                    <i class="fa-solid fa-phone"></i>
                  </div>
                  <p className='text-white'>00+123-456-789</p>
                </div>
                <div className='flex items-center gap-4 mt-5'>
                  <div className='w-[35px] h-[35px] flex items-center justify-center text-white border rounded-full cursor-pointer hover:bg-[#ffbe00] hover:border-[#ffbe00] duration-300'>
                    <i class="fa-solid fa-envelope"></i>
                  </div>
                  <a className='text-white' href="mailto:info@fansclub.com">info@fansclub.com</a>
                </div>
                <div className='flex items-center gap-4 mt-5'>
                  <div className='w-[35px] h-[35px] flex items-center justify-center text-white border rounded-full cursor-pointer hover:bg-[#ffbe00] hover:border-[#ffbe00] duration-300'>
                    <i class="fa-solid fa-link"></i>
                  </div>
                  <a className='text-white' target='_blank' href='https://www.fansclub.com' >www.fansclub.com</a>
                </div>
              </div>
            </div>

            <div>
              <p className='text-white text-2xl font-semibold'>Usefull Link</p>
              <div className='border-t-4 mt-4 mb-6 w-14'>
              </div>

              <div className='flex gap-4 items-center text-white py-2 border-b hover:bg-[#ffbe00] hover:pl-2 duration-500 cursor-pointer'>
                <i class="fa-solid fa-chevron-right"></i><p className='uppercase font-semibold text-sm'>Home</p>
              </div>
              <div className='flex gap-4 items-center text-white py-2 border-b hover:bg-[#ffbe00] hover:pl-2 duration-500 cursor-pointer'>
                <i class="fa-solid fa-chevron-right"></i><p className='uppercase font-semibold text-sm'>Magazine</p>
              </div>
              <div className='flex gap-4 items-center text-white py-2 border-b hover:bg-[#ffbe00] hover:pl-2 duration-500 cursor-pointer'>
                <i class="fa-solid fa-chevron-right"></i><p className='uppercase font-semibold text-sm'>News</p>
              </div>
              <div className='flex gap-4 items-center text-white py-2 border-b hover:bg-[#ffbe00] hover:pl-2 duration-500 cursor-pointer'>
                <i class="fa-solid fa-chevron-right"></i><p className='uppercase font-semibold text-sm'>Players</p>
              </div>
              <div className='flex gap-4 items-center text-white py-2 border-b hover:bg-[#ffbe00] hover:pl-2 duration-500 cursor-pointer'>
                <i class="fa-solid fa-chevron-right"></i><p className='uppercase font-semibold text-sm'>Post Types</p>
              </div>
            </div>

            <div>
              <p className='text-white text-2xl font-semibold'>Download App</p>
              <div className='border-t-4 mt-4 mb-6 w-14'>
              </div>

              <div className='flex items-center gap-4'>
                <div className='w-[35px] h-[35px] flex items-center justify-center text-white border rounded-full cursor-pointer hover:bg-[#ffbe00] hover:border-[#ffbe00] duration-300'>
                  <i class="fa-brands fa-android"></i>
                </div>
                <p className='text-white cursor-pointer'>Android App</p>
              </div>
              <div className='flex items-center gap-4 mt-5'>
                <div className='w-[35px] h-[35px] flex items-center justify-center text-white border rounded-full cursor-pointer hover:bg-[#ffbe00] hover:border-[#ffbe00] duration-300'>
                  <i class="fa-brands fa-apple"></i>
                </div>
                <p className='text-white cursor-pointer'>iOS App</p>
              </div>
              <div className='flex items-center gap-4 mt-5'>
                <div className='w-[35px] h-[35px] flex items-center justify-center text-white border rounded-full cursor-pointer hover:bg-[#ffbe00] hover:border-[#ffbe00] duration-300'>
                  <i class="fa-brands fa-chrome"></i>
                </div>
                <p className='text-white cursor-pointer'>For Chrome</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='border-t border-gray-500 sm:px-0 px-4'>
        <div className='xl:px-[160px] py-6 flex justify-between items-center flex-wrap gap-5'>
          <p className='text-white'>2024 @ Fans Club</p>
          <div className='flex items-center gap-5'>
            <div className='w-[35px] h-[35px] flex items-center justify-center text-white border rounded-full cursor-pointer hover:bg-[#ffbe00] hover:border-[#ffbe00] duration-300'>
              <i className="fa-brands fa-facebook-f"></i>
            </div>
            <div className='w-[35px] h-[35px] flex items-center justify-center text-white border rounded-full cursor-pointer hover:bg-[#ffbe00] hover:border-[#ffbe00] duration-300'>
              <i className="fa-brands fa-google-plus-g cursor-pointer"></i>
            </div>
            <div className='w-[35px] h-[35px] flex items-center justify-center text-white border rounded-full cursor-pointer hover:bg-[#ffbe00] hover:border-[#ffbe00] duration-300'>
              <i className="fa-brands fa-linkedin-in cursor-pointer"></i>
            </div>
            <div className='w-[35px] h-[35px] flex items-center justify-center text-white border rounded-full cursor-pointer hover:bg-[#ffbe00] hover:border-[#ffbe00] duration-300'>
              <i className="fa-brands fa-twitter cursor-pointer"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}