import React from 'react';
import Header from '../../Components/Header/Header.jsx';
import Footer from '../../Components/Footer/Footer.jsx';
import MainSlider from '../../Components/MainSlider.jsx';
import Home from '../../Components/Home.jsx';

export default function Main() {
    return (
        <div>
            <Header />

            <MainSlider />
            <Home />

            <Footer />
        </div>
    )
}
